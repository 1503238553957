import { motion } from "framer-motion";

const Reviews = () => {
  return (
    <section id="reviews" className="pt-32 pb-16">
      {/* HEADING */}
      <motion.div
        className="md:w-1/3 text-center md:text-left"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <p className="font-playfair font-semibold text-4xl mb-5">REVIEWS</p>
        <p className="mt-10 font-poppins">
          Take a look for yourself, here is what our customers are saying about
          us!😊
        </p>
        <p className="mt-10 mb-7 text-sm text-center md:text-start">
          Taken straight from our Google Reviews.
        </p>
      </motion.div>

      {/* REVIEWS */}
      <div className="md:flex md:justify-between gap-8">
        <motion.div
          className="mx-auto relative bg-accent-yellow max-w-[400px] overflow-y-auto flex flex-col justify-end p-16 mt-24"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.6 }}
          variants={{
            hidden: { opacity: 0, scale: 0.8 },
            visible: { opacity: 1, scale: 1 },
          }}
        >
          <p className="font-playfair text-3xl text-center">Maleeha Hussain</p>
          <p className="font-playfair text-6xl">“</p>
          <p className="text-center">
            Very professional service. The owner himself comes for inspection
            and delivery, and knows what he is talking about. Eliminex actually
            made sure their service was worth it. Highly recommend if you want
            prompt eradication.
          </p>
        </motion.div>

        <motion.div
          className="mx-auto relative bg-red max-w-[400px] overflow-y-auto flex flex-col justify-end p-16 mt-24"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.6 }}
          variants={{
            hidden: { opacity: 0, scale: 0.8 },
            visible: { opacity: 1, scale: 1 },
          }}
        >
          <p className="font-playfair text-3xl text-center">Reem Taghleb</p>
          <p className="font-playfair text-6xl">“</p>
          <p className="text-center">
            Great company, efficient and nice. They take your concerns serious
            and they work on it to resolve or prevent the pest issues you may
            have within few months only. Reccomend them.
          </p>
        </motion.div>

        <motion.div
          className="mx-auto relative bg-cream-white max-w-[400px] overflow-y-auto flex flex-col justify-end p-16 mt-24"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.6 }}
          variants={{
            hidden: { opacity: 0, scale: 0.8 },
            visible: { opacity: 1, scale: 1 },
          }}
        >
          <p className="font-playfair text-3xl text-center">Ebhrahim Shaikh</p>
          <p className="font-playfair text-6xl">“</p>
          <p className="text-center">
            They were professional, prompt and polite. These guys took their
            time and covered every inch of the house. They took the time to
            explain preventive measures and important tips to prevent
            recurrence. I highly recommend these guys!!!
          </p>
        </motion.div>
      </div>

      <div className="md:flex md:justify-between gap-8">
        <motion.div
          className="mx-auto relative bg-cream-white max-w-[400px] overflow-y-auto flex flex-col justify-end p-16 mt-24"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.6 }}
          variants={{
            hidden: { opacity: 0, scale: 0.8 },
            visible: { opacity: 1, scale: 1 },
          }}
        >
          <p className="font-playfair text-3xl text-center">Robert Morris</p>
          <p className="font-playfair text-6xl">“</p>
          <p className="text-center">
            I called Eliminex Pest Management with an issue of CARPENTER ANTS
            and two exterminators showed up by same day noon. They did a
            thorough inspection, then treated the house. Technicians did a great
            job! Would highly recommend this company.
          </p>
        </motion.div>
        <motion.div
          className="mx-auto relative bg-baby-blue max-w-[400px] overflow-y-auto flex flex-col justify-end p-16 mt-24"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.6 }}
          variants={{
            hidden: { opacity: 0, scale: 0.8 },
            visible: { opacity: 1, scale: 1 },
          }}
        >
          <p className="font-playfair text-3xl text-center">John Gomes</p>
          <p className="font-playfair text-6xl">“</p>
          <p className="text-center">
            The service of Eliminex Pest Management is very good. They are very
            skilled and friendly. They worked successfully to control my roaches
            problem. I strongly recommend them.
          </p>
        </motion.div>
        <motion.div
          className="mx-auto relative bg-accent-yellow max-w-[400px] overflow-y-auto flex flex-col justify-end p-16 mt-24"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.6 }}
          variants={{
            hidden: { opacity: 0, scale: 0.8 },
            visible: { opacity: 1, scale: 1 },
          }}
        >
          <p className="font-playfair text-3xl text-center">Rai Aon</p>
          <p className="font-playfair text-6xl">“</p>
          <p className="text-center">
            They did a great job of spraying the rooms for pest, were quick to
            arrive, affordable, & they spend good amount of time spraying
            around, I highly recommended these guys!! Thanks
          </p>
        </motion.div>
      </div>

      <div className="md:flex md:justify-between gap-8">
        <motion.div
          className="mx-auto relative bg-accent-yellow max-w-[400px] overflow-y-auto flex flex-col justify-end p-16 mt-24"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.6 }}
          variants={{
            hidden: { opacity: 0, scale: 0.8 },
            visible: { opacity: 1, scale: 1 },
          }}
        >
          <p className="font-playfair text-3xl text-center">Mary Chapman</p>
          <p className="font-playfair text-6xl">“</p>
          <p className="text-center">
            Very happy with their service and result. Professional, polite and
            our situation was resolved to our satisfaction after the first
            company we hired did not get rid of the problem. I highly recommend
            their services.
          </p>
        </motion.div>
        <motion.div
          className="mx-auto relative bg-red max-w-[400px] overflow-y-auto flex flex-col justify-end p-16 mt-24"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.6 }}
          variants={{
            hidden: { opacity: 0, scale: 0.8 },
            visible: { opacity: 1, scale: 1 },
          }}
        >
          <p className="font-playfair text-3xl text-center">Ortheta Anan</p>
          <p className="font-playfair text-6xl">“</p>
          <p className="text-center">
            Had a case of bed bugs and these guys took care of it easily.
            Courteous and efficient. Would highly recommend!
          </p>
        </motion.div>
        <motion.div
          className="mx-auto relative  bg-cream-white max-w-[400px] overflow-y-auto flex flex-col justify-end p-16 mt-24"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.6 }}
          variants={{
            hidden: { opacity: 0, scale: 0.8 },
            visible: { opacity: 1, scale: 1 },
          }}
        >
          <p className="font-playfair text-3xl text-center">Yasmin Umar</p>
          <p className="font-playfair text-6xl">“</p>
          <p className="text-center">
            Excellent service - I am very happy with the results. They are
            professional, experienced, knowledgeable and incredibly kind! They
            solved my problem right away and for a very reasonable price. I
            would definitely recommend.
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default Reviews;