import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";

const About = () => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section id="about" className="pt-10 pb-24">
      {/* HEADER AND IMAGE SECTION */}
      <div className="md:flex md:justify-between md:gap-16 mt-32">
        <motion.div
          className="md:w-1/3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="font-playfair font-semibold text-4xl mb-5 z-30 basis-2/5 mt-18 md:mt-36">
            WHO ARE WE?
          </p>
          <p className="mt-10 mb-7">
            At Eliminex Pest Management, we're your licensed experts, available
            anytime to tackle pests. Our skilled team guarantees effective
            results while prioritizing the environment.
            <br />
            Specializing in Bed Bugs, Cockroaches, Ants, Flies, Rats, Mice,
            Spiders, Wasps, and more, we offer fast service, satisfaction
            assurance, and competitive prices.
            <br />
            <br />
            Call or text 647-303-3423 for immediate solutions. Your pest-free
            space begins here!
          </p>
        </motion.div>

        <div className="basis-3/5 z-10 mt-18 md:mt-36 flex justify-center md:order-2">
          {isAboveLarge ? (
            <div className="z-10 w-full max-w-[400px] md:max-w-[600px]">
              <img
                alt="satisfied customers"
                className="z-10"
                src="assets/happy-customer.svg"
              />
            </div>
          ) : (
            <img
              alt="satisfied customers"
              className="z-10"
              src="assets/happy-customer.svg"
            />
          )}
        </div>

      </div>
    </section>
  );
};

export default About;
