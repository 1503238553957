import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Home = ({ setSelectedPage }) => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section
      id="home"
      className="md:flex md:justify-between md:items-center gap-16 md:h-full py-10 mt-24"
    >
      {/* IMAGE SECTION */}
      <div className="basis-3/5 z-10 mt-18 md:mt-36 flex justify-center md:order-2">
        {isAboveLarge ? (
          <img
            alt="pest control"
            className="hover:filter hover:saturate-200 transition duration-500 z-10 w-full max-w-[400px] md:max-w-[600px]"
            src="assets/pest-control-crew.svg"
          />
        ) : (
          <img
            alt="pest control"
            className="z-10 w-full max-w-[400px] md:max-w-[600px]"
            src="assets/pest-control-crew.svg"
          />
        )}
      </div>

      {/* MAIN TEXT */}
      <div className="z-30 basis-2/5 mt-18 md:mt-36">
        {/* HEADINGS */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="text-6xl text-grey font-playfair z-10 text-center md:text-start">
            Eliminex Pest Management
          </p>

          <p className="mt-10 mb-7 text-sm text-center md:text-start">
            Licensed by the Ministry of Environment.
          </p>
        </motion.div>

        {/* CALL TO ACTIONS */}
        <motion.div
          className="flex mt-5 justify-center md:justify-start"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <AnchorLink
            className="bg-green text-white rounded-sm py-3 px-7 font-semibold
              hover:bg-baby-blue hover:text-grey transition duration-500"
            onClick={() => setSelectedPage("contact")}
            href="#contact"
          >
            Contact Us!
          </AnchorLink>
        </motion.div>
      </div>
    </section>
  );
};

export default Home;
