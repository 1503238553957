const Footer = () => {
    return (
      <footer className="h-36 bg-green pt-10">
        <div className="w-10/12 mx-auto">
          <div className="md:flex justify-center md:justify-between text-center">
            <p className="font-playfair font-semibold text-2xl text-white pb-2">
              ELIMINEX PEST MANAGEMENT
            </p>
            <p className="font-playfair text-md text-white">
              ©2017 ELIMINEX. All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
    );
  };
  
  export default Footer;