import Navbar from "./components/Navbar";
import DotGroup from "./components/DotGroup";
import Home from "./components/Home";
import About from "./components/About";
import Services from "./components/Services";
import Reviews from "./components/Reviews";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import { useState } from "react";
import useMediaQuery from "./hooks/useMediaQuery";

function App() {
  const [selectedPage, setSelectedPage] = useState('home');
  const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)");

  return (
    <div className="App bg-white">
      <Navbar selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
      <div className="w-5/6 mx-auto md-h-full">
        {isAboveMediumScreens && (
          <DotGroup selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
        )}
        <Home setSelectedPage={setSelectedPage} />
      </div>
      <div className="w-5/6 mx-auto md-h-full">
        <About />
      </div>
      <div className="w-5/6 mx-auto md-h-full">
        <Services />
      </div>
      <div className="w-5/6 mx-auto md-h-full">
        <Reviews />
      </div>
      <div className="w-5/6 mx-auto md-h-full">
        <Contact />
      </div>
      <Footer />
    </div>
  );
}

export default App;
