import { motion } from "framer-motion";

const Services = () => {
  return (
    <section id="services" className="pt-10 pb-24">
      <div className="text-center">
        <h2 className="font-playfair text-4xl font-semibold mb-8 md:mb-20 md:mt-18 md:mt-36">
          Our Services
        </h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {/* Service Column 1 */}
        <div className="flex flex-col items-center">
          <p className="text-xl text-center mb-4 font-poppins">
            Residential Services
          </p>
          <motion.div
            whileHover={{ y: -10, scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="z-10 mb-4"
          >
            <img
              alt="ants"
              src="assets/ants.jpg"
              style={{ maxWidth: "200px", maxHeight: "200px" }}
            />
          </motion.div>

          <p className="text-sm text-grey text-center mb-4">
            Eliminex Pest Management offers residential protection coverage for
            either a single pest or for multiple pest problems in and around
            your home.
            <br />
            <br />
            All plans come equipped with a full interior and exterior
            inspection.
          </p>
        </div>

        {/* Service Column 2 */}
        <div className="flex flex-col items-center">
          <p className="text-xl text-center mb-4 font-poppins">
            Commercial Services
          </p>
          <motion.div
            whileHover={{ y: -10, scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="z-10 mb-4"
          >
            <img
              alt="mice"
              className="z-10 mb-4"
              src="assets/mice.jpg"
              style={{ maxWidth: "200px", maxHeight: "200px" }}
            />
          </motion.div>

          <p className="text-sm text-grey text-center mb-4">
            You will require a pest control provider that will protect your
            customers from health hazards such as pests and other nuances.
            <br />
            <br />
            This plan prevents your business from being shut down.
          </p>
        </div>

        {/* Service Column 3 */}
        <div className="flex flex-col items-center">
          <p className="text-xl text-center mb-4 font-poppins">
            Single Service Program
          </p>
          <motion.div
            whileHover={{ y: -10, scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="z-10 mb-4"
          >
            <img
              alt="wasps"
              className="z-10 mb-4"
              src="assets/wasp.jpg"
              style={{ maxWidth: "200px", maxHeight: "200px" }}
            />
          </motion.div>
          <p className="text-sm text-grey text-center mb-4">
            This plan focuses on solving your immediate pest problem without
            signing a long term contract. Our work is guaranteed for three
            months and any subsequent treatments during this time are free.
            <br />
            <br />
            Extended warranties are also available.
          </p>
        </div>

        {/* Service Column 4 */}
        <div className="flex flex-col items-center">
          <p className="text-xl text-center mb-4 font-poppins">
            Single Pest Program
          </p>
          <motion.div
            whileHover={{ y: -10, scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="z-10 mb-4"
          >
            <img
              alt="bed bugs"
              className="z-10 mb-4"
              src="assets/bed-bug.jpg"
              style={{ maxWidth: "200px", maxHeight: "200px" }}
            />
          </motion.div>

          <p className="text-sm text-grey text-center mb-4">
            This program is guaranteed for six months and any subsequent
            treatments during this time are free. Extended warranties are also
            available.
            <br />
            <br />
            This plan is designed to provide you the protection from a single
            pest.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Services;
